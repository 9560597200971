body{
  max-width: 100%;
  overflow-x: hidden;
}
.page {
  background-image: linear-gradient(to bottom, rgba(43, 12, 8),rgba(255, 170, 170)) ,url("../src/components/layout.png");
  background-blend-mode: overlay;
 
}

@media screen and (max-width: 400px) {
  #explore ,
  #donation,
  #membership,
  #magazine,
  #testimonials,
  #consultancy,
  #contact,
  #footer {
    box-sizing: border-box; /* Include padding and border in the width */
    width: 100%; /* Full width but no more than 100% */
    padding: 20px;
  }
}
